import {useInterval, useWebSocket} from "ahooks";
import config from "../../config";
import useActiveState from "../general/useActiveState";
import Dialog from "../general/Dialog";
import {useHistory} from "react-router-dom";

export default () => {
  const history = useHistory();
  const [app] = useActiveState('app');
  const [gaming, setGaming] = useActiveState('gaming');
  const heartbeat = () => {
    try {
      sendMessage(JSON.stringify({
        game_id: app.gameId,
        token: app.token,
        username: app.username,
        xh_username: app.altUsername,
      }));
    } catch (e) {

    }
  }
  const {sendMessage} = useWebSocket(config.api.heartbeat, {
    onOpen: heartbeat,
    onMessage: e => {
      const data = JSON.parse(e.data);
      if (data?.status === 1) {
        setGaming(false);
      }
      Dialog.alert('提示', data.msg, () => {
        if (data?.status === 1) {
          history.replace('/login');
        }
      });
    },
  });
  useInterval(heartbeat, 10000);

  return null;
}
