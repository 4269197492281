import {useHistory, useLocation} from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import useActiveState from "../general/useActiveState";
import {useEffect, useRef, useState} from "react";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import {useEventListener} from "ahooks";
import URI from "urijs";
import Assist from "../game/Assist";
import Heartbeat from "../game/Heartbeat";
import {v4 as uuidv4} from 'uuid';
import md5 from "blueimp-md5";
import dayjs from 'dayjs';

export default () => {
  const history = useHistory();
  const location = useLocation();
  const [params] = useUrlState();
  const [key, setKey] = useState();
  const [app, setApp] = useActiveState('app');
  const [gaming, setGaming] = useActiveState('gaming');

  useEffect(() => {
    let outside = /^\/(login|phonelogin|passwordlogin|register|agreement|forget|alt|realname)/.test(location.pathname);
    if (outside) {
      return;
    }
    if (location.pathname.startsWith('/service')) {
      return;
    }
    if ((params.altUsername && app.altUsername != params.altUsername) || !gaming) {
      let altUsername = params.altUsername || app.altUsername;
      if (!app.username || !altUsername) {
        history.replace('/login');
        return;
      }
      Dialog.loading();
      axios.get('/xh/login', {params: {xh_username: altUsername, is_default: params.autoLogin}})
        .finally(() => {
          Dialog.close();
        })
        .then(({data: resData}) => {
          if (resData.code != 0) {
            Dialog.alert('提示', resData.msg);
            return;
          }
          setApp({altUsername});
          setGaming(true);
          setKey(uuidv4());
          history.replace('/');

          axios.get('/game/getUrl')
            .then(({data: resData}) => {
              if (resData.code != 0) {
                Dialog.alert('提示', resData.msg);
                return;
              }
              setUrl(resData.data.url);
            })
            .catch(err => {
              Dialog.alert('提示', err.message);
            });
        })
        .catch(err => {
          Dialog.alert('提示', err.message);
        });
    }
  }, [location.pathname, params.altUsername, gaming]);

  useEventListener('message', e => {
    let data = e.data;
    console.log('--ay--', data);
    let {operation, param} = data;
    if (operation === 'config') {

    } else if (operation === 'setrole') {
      axios.post('/role/setRole', {
        username: app.username,
        xh_username: param?.username,
        roleid: param?.roleid,
        rolename: param?.rolename,
        rolelevel: param?.rolelevel,
        zoneid: param?.serverid,
        zonename: param?.servername,
        ext: undefined,
        time: param?.time,
        sign: param?.sign,
      });
    } else if (operation === 'pay') {
      let url = URI('/pay').query({
        amount: param?.amount,
        productName: param?.productname,
        productDesc: param?.productdesc,
        orderId: param?.attach,
        noncestr: param?.noncestr,
        roleId: param?.roleid,
        serverId: param?.serverid,
        timestamp: param?.timestamp,
        username: param?.username,
        sign: param?.sign,
      }).toString();
      history.replace(url);
    } else if (operation === 'share') {

    }
  });

  const ref = useRef();
  const [url, setUrl] = useState();

  return gaming ? <div className="game-game" ref={ref}>
    <iframe src={url} key={key}/>
    <Assist container={ref}/>
    <Heartbeat/>
  </div> : null;
}
