import { useLockFn, useMount, useUpdate } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import Image from "../general/Image";
import useActiveState from "../general/useActiveState";
import dayjs from "dayjs";

export default () => {
  const [app] = useActiveState("app");
  const [data, setData] = useState(general.rebate || {});
  const [params] = useUrlState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios
      .get(`/activityRebate/gameActivityInfo?id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setData(res.data.data);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  const receive = useLockFn(async () => {
    if (!data.isSureApply) {
      return;
    }
    Dialog.loading();
    await axios
      .post("/activityRebate/gameActivityApply", {
        id: data.newid,
        role_id: app.roleId,
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success("申请成功");
        load();
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  return (
    <div className="rebate-rebate">
      <div className="body">
        <div className="head">
          <div className="icon">
            <Image src={data.logo} />
          </div>
          <div className="info">
            <div className="name">{data.title}</div>
            <div className="desc">{data.description}</div>
          </div>
        </div>
        <div
          className="data"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
        <div className="require">
          {/* 满足条件还差的充值金额：<span>¥{data.diffAmount}</span>
          <br /> */}
          充值累计时间：
          {data.start_time
            ? dayjs.unix(data.start_time).format("YYYY-MM-DD") + "~"
            : null}
          {data.deadline == 1
            ? "永久"
            : dayjs.unix(data.deadline).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="foot">
        <div
          className={`operate ${!data.is_apply_rebate ? "auto" : ""} complete ${
            !data.isSureApply ? "nothing" : ""
          }`}
        />
      </div>
    </div>
  );
};
