import {useLockFn, useMount} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import {useContext, useEffect, useState} from "react";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import ActiveContext from "../general/ActiveContext";

export default () => {
  const {setCdata} = useContext(ActiveContext);
  const [data, setData] = useState(general.msg || {});
  useEffect(() => {
    setCdata({title: data.title});
  }, [data.title]);
  const [params] = useUrlState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios.get(`/msg/getMsgInfo?id=${params.id}`)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        try {
          general.msg.readOrNot = false;
        } catch (e) {

        }
        setData(res.data.data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return <div className="user-msg">
    <div className="content" dangerouslySetInnerHTML={{__html: data.content}}/>
    <div className="time">{data.create_time}</div>
  </div>;
}