import { Swiper, SwiperSlide } from "swiper/react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "swiper/swiper-bundle.css";
import { useState } from "react";
import { useBoolean, useLockFn, useMount, useThrottleEffect } from "ahooks";
import Dialog from "./Dialog";
import axios from "../../axios";

const Tips = ({ close }) => {
  const [datas, setDatas] = useState([]);
  const [current, setCurrent] = useState(0);
  const [notify, setNotify] = useState();
  const load = useLockFn(async () => {
    Dialog.loading();
    await axios
      .get(`/game/gameActivityList`)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data.list);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  useThrottleEffect(() => {
    if (notify === undefined) {
      return;
    }
    axios
      .post(`/game/setActivityStatus`, { state: notify })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          console.error(res);
          return;
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [notify]);

  return (
    <div className="tips-index">
      <div className="container">
        <div className="head">
          <div className="title">游戏活动公告</div>
          <CloseOutlined className="close" onClick={close} />
        </div>
        <div className="body">
          <Swiper autoHeight onSlideChange={(e) => setCurrent(e.activeIndex)}>
            {datas.map((item) => (
              <SwiperSlide key={item.newid} className="body-container">
                <div className="title">{item.title}</div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="foot">
          <div className="items">
            {datas.map((item, index) => (
              <div
                key={item.newid}
                className={`item ${index == current ? "active" : ""}`}
              />
            ))}
          </div>
          <div className="ctrl">
            <div
              className={`input ${notify ? "active" : ""}`}
              onClick={() => setNotify(!notify)}
            >
              <CheckOutlined className="icon" />
            </div>
            <div className="text">今日不再提醒</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const [state, { setTrue: show, setFalse: close }] = useBoolean();

  useMount(() => {
    axios.get("/game/getActivityStatus").then(({ data: res }) => {
      if (res.code != 0) {
        Dialog.error(res.msg);
        return;
      }
      if (res?.data?.is_show) {
        show();
      }
    });
  });

  return state ? <Tips close={close} /> : null;
};
