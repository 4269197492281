import { useLockFn, useMount } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useState } from "react";
import dayjs from "dayjs";
import Empty from "../general/Empty";

export default () => {
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios
      .get("/openService/openServiceList")
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return (
    <div
      className={`server-servers ${
        datas.length == 0 && !loading ? "empty" : ""
      }`}
    >
      <div className="title">开服信息仅供参考，实际开服以游戏内为准</div>
      {datas.length > 0 ? (
        <div className="list">
          <table>
            <tbody>
              {datas.map((item, index) => (
                <tr key={index}>
                  <td>{dayjs.unix(item.starttime).format("YYYY-MM-DD")}</td>
                  <td>{dayjs.unix(item.starttime).format("HH:mm")}</td>
                  <td>{item.addressnum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
      {datas.length == 0 && !loading ? <Empty /> : null}
    </div>
  );
};
