import { Link, useHistory } from "react-router-dom";
import { useMount } from "ahooks";
import { useState } from "react";
import axios from "../../axios";
import Dialog from "../general/Dialog";
import useActiveState from "../general/useActiveState";

export default () => {
  const history = useHistory();
  const [app, setApp] = useActiveState("app");
  const [data, setData] = useState({});
  useMount(async () => {
    Dialog.loading();
    try {
      let { data: result } = await axios.get("/user/getRandName");
      if (result?.data?.username) {
        setData({ ...data, username: result?.data?.username });
      }
    } catch (e) {
    } finally {
      Dialog.close();
    }
  });

  const submit = () => {
    Dialog.loading();
    axios
      .post("/user/register", data)
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: result }) => {
        if (result.code != 0) {
          Dialog.error(result.msg);
          return;
        }
        setApp({
          username: result.data.token,
          token: result.data.token,
        });
        history.replace("/alt");
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  };

  return (
    <div className="account-register">
      <div className="tips">以下账号由系统自动生成</div>
      <div className="input">
        <div className="label">账号</div>
        <input
          type="text"
          placeholder="请输入6到16位数字或字母"
          value={data.username || ""}
          onChange={(e) => setData({ ...data, username: e.target.value })}
        />
      </div>
      <div className="input">
        <div className="label">密码</div>
        <input
          type="password"
          placeholder="请输入密码"
          value={data.password}
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
      </div>
      <div className="btn" onClick={submit}>
        立即注册
      </div>
      <div className="agreement">
        注册即同意
        <Link replace to="/agreement">
          {app.agreementList?.login?.title}
        </Link>
      </div>
    </div>
  );
};
