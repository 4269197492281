import axios from "../../axios";
import { LoadingOutlined } from "@ant-design/icons";
import Dialog from "../general/Dialog";
import { useLockFn, useReactive } from "ahooks";
import React, { useContext, useEffect, useState } from "react";
import ActiveContext from "../general/ActiveContext";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";

export default () => {
  const [params] = useUrlState();
  const { setCdata } = useContext(ActiveContext);
  const data = useReactive({ xh_id: params.id, xh_name: params.alias });
  useEffect(() => {
    setCdata({ title: data.xh_id ? "修改名称" : "添加小号" });
  }, []);

  const [saving, setSaving] = useState();
  const save = useLockFn(async () => {
    setSaving(true);
    await axios
      .post(data.xh_id ? "/xh/updateXhName" : "/xh/addXh", data)
      .finally(() => {
        Dialog.close();
        setSaving(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        Dialog.success("操作成功", () => {
          general.goBack();
          general.load();
        });
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  return (
    <div className="alt-alt">
      <div className="body">
        <div className="tips">支持中英文和数字，不超过24个字符</div>
        <input
          type="text"
          placeholder="请输入小号名称"
          value={data.xh_name || ""}
          onChange={(e) => (data.xh_name = e.target.value)}
        />
      </div>
      <div className={`operate ${saving ? " saving" : ""}`} onClick={save}>
        <LoadingOutlined className="icon" />
        <div className="text">{params.id ? "保存" : "添加"}</div>
      </div>
    </div>
  );
};
