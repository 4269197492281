import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useMount } from "ahooks";
import { Link } from "react-router-dom";
import { CopyOutlined, RightOutlined, BellOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import useActiveState from "../general/useActiveState";
import ActiveContext from "../general/ActiveContext";
import { useContext, useEffect } from "react";
import badgeState from "../general/badgeState";
import { useRecoilState } from "recoil";

export default () => {
  const [badge, setBadge] = useRecoilState(badgeState);
  const [app] = useActiveState("app");
  const { setCdata } = useContext(ActiveContext);
  const [user, setUser] = useActiveState("user");
  useEffect(() => {
    setCdata({
      operate: (
        <Link
          replace
          to="/user/msgs"
          className={`user-index-msg ${badge.msg ? "badge" : ""}`}
        >
          <BellOutlined className="icon" />
          {badge.msg ? <div className="badge">({badge.msg})</div> : null}
        </Link>
      ),
    });
  }, [badge.msg]);
  useMount(() => {
    Dialog.loading();
    axios
      .get("/user/personalCenter")
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setUser(res.data.data);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  return (
    <div className="user-index">
      <div className="head">
        <div className="avatar">
          <img src={user.userlogo} alt="" />
        </div>
        <div className="data">
          <div className="username">
            <div className="value">{user.username}</div>
            <CopyToClipboard
              text={user.username}
              onCopy={() => Dialog.info("已复制")}
            >
              <CopyOutlined className="copy" />
            </CopyToClipboard>
          </div>
          <div className="ptb">平台币：{user.ptb_num}</div>
          {user.flb > 0 && <div className="ptb">福利币：{user.flb}</div>}
        </div>
        <Link replace to="/user/vouchers" className="voucher">
          代金券
        </Link>
      </div>
      {app.branch != "old" ? (
        <div className="alt">
          <Link replace to="/user/alt" className="item">
            <div className="text">我的小号</div>
            <div className="operate">
              <RightOutlined />
            </div>
          </Link>
        </div>
      ) : null}
      <div className="body">
        <Link replace to="/user/orders" className="item">
          <div className="text">订单记录</div>
          <div className="operate">
            <RightOutlined />
          </div>
        </Link>
        <Link replace to="/user/gifts" className="item">
          <div className="text">礼包领取内容</div>
          <div className="operate">
            <RightOutlined />
          </div>
        </Link>
        <Link replace to="/user/rebates" className="item">
          <div className="text">福利申请记录</div>
          <div className="operate">
            <RightOutlined />
          </div>
        </Link>
        <Link replace to="/user/password" className="item">
          <div className="text">修改密码</div>
          <div className="operate">
            <RightOutlined />
          </div>
        </Link>
        <Link replace to="/user/phone" className="item">
          <div className="text">绑定手机</div>
          <div className="operate">
            <RightOutlined />
          </div>
        </Link>
        <Link replace to="/user/anti-addiction" className="item">
          <div className="text">防沉迷系统</div>
          <div className="operate">
            <RightOutlined />
          </div>
        </Link>
      </div>
    </div>
  );
};
