import { useLockFn, useMount } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useState } from "react";
import Image from "../general/Image";
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Empty from "../general/Empty";

export default () => {
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const [current, setCurrent] = useState();
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios
      .get("/gift/packList")
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        console.log("礼包列表", res);

        let datas = res.data.data.packList;
        setDatas(datas);
        datas.length > 0 && setCurrent(datas[0]);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return (
    <div className="user-gifts">
      <div className="body">
        <div className="types">
          {datas.map((item, index) => (
            <div
              key={index}
              className={`type ${item == current ? "active" : ""}`}
              onClick={() => setCurrent(item)}
            >
              {item.name}
            </div>
          ))}
        </div>
        {current?.list?.length ? (
          <div className="items">
            {current?.list?.map((item) => (
              <div className="item" key={item.packid}>
                <div className="body" style={{ padding: 0 }}>
                  <div className="icon">
                    <Image src={item.game_img} alt="" />
                  </div>
                  <div className="info">
                    <div className="name">{item.packname}</div>
                    <div className="time">
                      有效期至：
                      {dayjs.unix(item.term_of_validity).format("YYYY-MM-DD")}
                    </div>
                  </div>
                </div>
                <div className="foot">
                  <div className="code">
                    <div className="name">礼包码：</div>
                    <div className="value">{item.code}</div>
                  </div>
                  <CopyToClipboard
                    text={item.code}
                    onCopy={() => Dialog.info("已复制")}
                  >
                    <div className="operate">复制</div>
                  </CopyToClipboard>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      {!current?.list?.length && !loading ? <Empty /> : null}
    </div>
  );
};
