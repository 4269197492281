import {env} from './env';

const config = {
  api: {
    url: 'http://api.bestgame99.com',
    heartbeat: 'wss://api.bestgame99.com/ws',
  },
  captcha: {
    appid: '2092825876',
  },
};

if (env === 'test') {
  config.api.url = 'http://testapi.bestgame99.com';
}

if (env !== 'dev') {
  config.api.url = '/api';
}

export default config;
