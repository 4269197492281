import {useState} from "react";
import useUrlState from "@ahooksjs/use-url-state";
import {useInterval, useLockFn} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import useActiveState from "../general/useActiveState";

export default () => {
  const [user, setUser] = useActiveState('user');
  const [params, setParams] = useUrlState({});

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    axios.get('/sms/getAuthSmsCode')
      .finally(() => {
        setCoding(false);
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setCodeTime(60);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const [data, setData] = useState({});
  const save = useLockFn(async () => {
    let reqData = {...data};
    if (user.authentication_info) {
      reqData.type = user.mobile == '' ? '1' : '2';
      reqData.param = user.mobile == '' ? data.password : data.code;
      if (!reqData.param) {
        Dialog.error(user.mobile == '' ? '请输入登录密码' : '请输入验证码');
        return;
      }
    }
    Dialog.loading();
    await axios.post(user.authentication_info ? '/user/modifyAuthentication' : '/user/authentication', reqData)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setParams({set: 0});
        setUser({...user, authentication_info: {...data, is_age_18: res.data.data.is_age_18}});
        console.log(res);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  return <div className="user-anti-addiction">
    {params.set == 1 ? <div className="data-from">
      <div className="title">应监管部门要求，为保障您的游戏体验，请尽快进行实名认证</div>
      <div className="input">
        <input type="text" placeholder="请输入真实姓名" onChange={e => setData({...data, name: e.target.value})}/>
      </div>
      <div className="input">
        <input type="text" placeholder="请输入身份证" onChange={e => setData({...data, number: e.target.value})}/>
      </div>
      {user.authentication_info ? <div>
        {user.mobile != '' ? <>
          <div className="tips">绑定的手机号：13227737527</div>
          <div className="input">
            <input type="text" placeholder="请输入验证码" onChange={e => setData({...data, code: e.target.value})}/>
            <div className={'codebtn' + (coding || codeTime ? ' disable' : '')} onClick={code}>
              {coding ? '获取中' : (codeTime ? (codeTime + '秒后重试') : '获取验证码')}
            </div>
          </div>
        </> : <div className="input">
          <input type="password" placeholder="请输入登录密码" onChange={e => setData({...data, password: e.target.value})}/>
        </div>}
      </div> : null}
      <div className="operate" onClick={save}>{user.authentication_info ? '修改实名信息' : '设置实名信息'}</div>
    </div> : <>
      {user.authentication_info?.is_age_18 ? <div className="data-success">
        用户姓名：{user.authentication_info.name.replace(/(.*)(.)$/, '$1*')}<br/>
        身份证：{user.authentication_info.number.replace(/^(\d{4})\d{10}(\d+)/, '$1********$2')}<br/>
        已成年：是<br/>
        游戏限制：无<br/>
      </div> : <div className="data-error">
        <div className="body">
          用户姓名：{user.authentication_info?.name ? user.authentication_info?.name?.replace(/(.*)(.)$/, '$1*') : '【游客模式】'}<br/>
          身份证：{user.authentication_info?.number?.replace(/(.*)(.)$/, '$1*')}<br/>
          已成年：{user.authentication_info ? (user.authentication_info?.is_age_18 ? '是' : '否') : null}<br/>
          <h1>游戏限制</h1>
          游戏时段时长<br/>
          <span>*</span>{user.authentication_info?.is_age_18 ? '正常' : '试玩1小时'}<br/>
          游戏充值<br/>
          <span>*</span>{user.authentication_info?.is_age_18 ? '正常' : '无法充值'}<br/>
        </div>
        <div className="operate" onClick={() => setParams({set: 1})}>修改实名信息</div>
      </div>}
    </>}
  </div>;
}