import {
  CloseOutlined,
  RightOutlined,
  CheckOutlined,
  LoadingOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import {
  useBoolean,
  useLocalStorageState,
  useLockFn,
  useMount,
  usePrevious,
  useReactive,
  useThrottleEffect,
} from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import useUrlState from "@ahooksjs/use-url-state";
import dayjs from "dayjs";
import useActiveState from "../general/useActiveState";
import { useHistory } from "react-router-dom";
import general from "../../general";
import _ from "lodash";

const PayDialog = ({ show = true, children, className }) => {
  return (
    <CSSTransition in={show} timeout={250} appear={true} unmountOnExit={true}>
      <div className={`pay-dialog ${className}`}>
        <div className="container">{children}</div>
      </div>
    </CSSTransition>
  );
};

const PaySuccess = ({ ad = {}, order = {} }) => {
  const history = useHistory();
  return (
    <PayDialog className="pay-success">
      <div className="body">
        <CheckOutlined className="icon" />
        <div className="title">充值成功</div>
        {/*<div className="ad">
        <a href={ad.advertisement_link} target="_blank"><img src={ad.advertisement_img}/></a>
      </div>*/}
      </div>
      <div className="close" onClick={() => history.replace("/")}>
        关闭
      </div>
    </PayDialog>
  );
};

export default () => {
  const history = useHistory();
  const [params] = useUrlState();
  const [user] = useActiveState("user");
  const [vouchers, setVouchers] = useState([]);
  const [voucher, setVoucher] = useState();
  const [voucherAuto, setVoucherAuto] = useState();
  const [voucherBest, setVoucherBest] = useState();
  const getVoucherBest = (vouchers) =>
    vouchers.length === 0
      ? null
      : vouchers.sort((a, b) => b.amount - a.amount)[0];
  const voucherPrev = usePrevious(voucher, (prev, next) => prev != next);
  const [
    voucherSelect,
    { setTrue: voucherSelectTrue, setFalse: voucherSelectFalse },
  ] = useBoolean();
  const [methods, setMethods] = useLocalStorageState("paytypes", []);
  const [method, setMethod] = useLocalStorageState("paytype");
  const methodPrev = usePrevious(method, (prev, next) => prev != next);
  const [
    methodSelect,
    { setTrue: methodSelectTrue, setFalse: methodSelectFalse },
  ] = useBoolean();
  const data = useReactive({
    payAmount: 1,
    rechargeDiscount: 1,
    amount: params.amount * 1 || 0,
    ptb: undefined,
    flb: undefined,
  });

  useMount(() => {
    Dialog.loading();
    axios
      .get(`/pay/getPayInformation?amount=${data.amount}`)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        let resdata = res.data.data;
        data.ptb = resdata.userPtbNum * 1 || 0;
        data.flb = resdata.flb * 1 || 0;
        data.rechargeDiscount = resdata.recharge_discount * 1 || 1;
        let vouchers = resdata.voucherList.map((item) => ({
          ...item,
          amount: item.amount * 1,
          deductAmount: item.amount > data.amount ? data.amount : item.amount,
        }));
        if (vouchers.length > 0) {
          let voucher = getVoucherBest(vouchers);
          setVoucher(voucher);
          setVoucherAuto(true);
          setVoucherBest(voucher);
        }
        setVouchers(vouchers);

        setMethods([
          ...resdata.payType.map((item) => ({
            ...item,
            sort: ["ptb", "flb"].includes(item.type) ? 1 : 0,
          })),
        ]);

        data.loaded = true;
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });

  useThrottleEffect(
    () => {
      let ts = methods.map((item) => {
        let t = { ...item };
        if (t.type == method?.type) {
          t.sort += 2;
        } else if (t.type == methodPrev?.type) {
          t.sort += 1;
        }
        return t;
      });
      ts.sort((a, b) => b.sort - a.sort);
      let t = ts.find((method) => method.enough);
      if (method?.type != t?.type) {
        setMethod(t);
      }
    },
    [methods, method, methodPrev],
    { wait: 100 }
  );

  useEffect(() => {
    setMethods((methods) =>
      methods.map((item) => ({
        ...item,
        enough: ["ptb", "flb"].includes(item.type)
          ? ["ptb", "flb"].includes(item.type) &&
            data.payAmount <= data[item.type] / 10
          : true,
        amount: ["ptb", "flb"].includes(item.type) ? data[item.type] : null,
      }))
    );
  }, [data.payAmount, data.loaded]);

  useThrottleEffect(
    () => {
      data.deductAmount = voucher?.deductAmount || 0;
      data.payAmount = data.amount - data.deductAmount;
      if (voucherPrev) {
        setVoucherAuto(false);
      }

      if (data.rechargeDiscount < 1) {
        console.log("-------------", data.payAmount, data.rechargeDiscount);
        data.payAmount = (data.payAmount * (data.rechargeDiscount * 10)) / 10;
        console.log("-------------", data.payAmount);
      }
      data.payAmount = _.round(data.payAmount, 2);
    },
    [data.amount, data.rechargeDiscount, voucher, voucherPrev],
    { wait: 100 }
  );

  const [submitting, setSubmitting] = useState(false);
  const [paying, setPaying] = useState(false);
  const [payUrl, setPayUrl] = useState();
  const [querying, setQuerying] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ad, setAd] = useState();
  const pay = useLockFn(async () => {
    setPaying(false);
    setSubmitting(true);
    Dialog.loading();
    console.log("充值参数--------------------", params);
    await axios
      .post("/pay/placeOrder", {
        roleid: params.roleId,
        serverid: params.serverId,
        productname: params.productName,
        productdesc: params.productDesc,
        amount: params.amount,
        attach: params.orderId,
        pay_type: method.id || method.type,
        pay_source_type: 2,
        is_coupon: !!voucher,
        coupon_id: voucher?.id || undefined,
        timestamp: params.timestamp,
        noncestr: params.noncestr,
        sign: params.sign,
      })
      .finally(() => {
        Dialog.close();
        setSubmitting(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setPaying(true);
        let { data: resData } = res.data;
        data.orderId = resData.order_id;
        if (resData.pay_status == 1) {
          setPaying(false);
          setSuccess(true);
          return;
        }
        if (resData.wxPayUrl) {
          general.openUrl(resData.wxPayUrl);
        } else {
          general.openUrl(resData.zfbPayUrl || resData.zfbPayHtml);
        }
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    const query = async () => {
      if (!paying || querying) {
        return;
      }
      setQuerying(true);
      let res;
      try {
        res = await axios.get(`/pay/payStatus?order_id=${data.orderId}`);
      } catch (e) {
        console.error(e);
      }
      if (res?.data?.code == 0) {
        setPaying(false);
        setQuerying(false);
        setSuccess(true);
        setAd(res.data.data);
      } else {
        setTimeout(() => setQuerying(false), 1527);
      }
    };
    query();
  }, [paying, querying]);

  return (
    <div className="pay-pay">
      <div className="pay-container">
        <div className="pay-head">
          {payUrl ? (
            <LeftOutlined
              className="back"
              onClick={() => setPayUrl(undefined)}
            />
          ) : null}
          <div className="title">充值中心</div>
          <CloseOutlined
            className="close"
            onClick={() => history.replace("/")}
          />
        </div>
        <div className="pay-body">
          <div className="pay-main">
            <div className="pay-info">
              <div className="item">您当前使用的账号是：{user.username}</div>

              {methods.filter((data) => ["ptb", "flb"].includes(data.type))
                .length && (
                <>
                  <div className="item">
                    您当前拥有
                    {methods.map((data) => data.type).includes("ptb") && (
                      <>
                        <span>{data.ptb}</span> 个平台币{" "}
                      </>
                    )}
                    {methods.map((data) => data.type).includes("flb") && (
                      <>
                        <span>{data.flb}</span>个福利币{" "}
                      </>
                    )}
                  </div>
                </>
              )}

              {voucherAuto ? (
                <div className="item voucher-autoed">
                  已为您选择最佳优惠组合
                </div>
              ) : null}
            </div>
            <div className="pay-data">
              <div className="pay-item">
                <div className="name">订单金额：</div>
                <div className="value order">¥ {data.amount}</div>
              </div>
              <div className="pay-item">
                <div className="name">代金券：</div>
                <div className="value voucher" onClick={voucherSelectTrue}>
                  <div className="text">
                    {voucher ? (
                      `-${voucher.deductAmount}`
                    ) : (
                      <div>{vouchers.length}张可以用</div>
                    )}
                  </div>
                  <RightOutlined className="more" />
                </div>
              </div>

              {data.rechargeDiscount < 1 ? (
                <div className="pay-item">
                  <div className="name">折扣：</div>
                  <div className="value order">
                    {data.rechargeDiscount * 10} 折
                  </div>
                </div>
              ) : null}

              <div className="pay-item">
                <div className="name">支付方式：</div>
                <div className="value method" onClick={methodSelectTrue}>
                  <div className="all">
                    {methods.map((item) =>
                      item.type != method?.type && !item.pay_source_type ? (
                        <img
                          key={item.type}
                          src={`/static/img/${item.id || item.type}-icon.png`}
                          className="item"
                        />
                      ) : null
                    )}
                  </div>
                  {method ? (
                    <div className="current">
                      <img
                        src={`/static/img/${method.id || method.type}-icon.png`}
                        alt=""
                      />
                      <div className="text">{method.name}</div>
                    </div>
                  ) : null}
                  <RightOutlined className="more" />
                </div>
              </div>
              <div className="pay-item">
                <div className="name">您需要支付：</div>
                <div className="value pay">
                  ¥{data.payAmount}（已抵扣-¥{data.deductAmount}）
                </div>
              </div>
            </div>
          </div>
          <div className={`operate ${submitting ? "ing" : ""}`} onClick={pay}>
            <LoadingOutlined className="icon" />
            <div className="text">充值</div>
          </div>
        </div>
        {payUrl ? (
          <div className="pay-iframe">
            <iframe src={payUrl} />
          </div>
        ) : null}
        <PayDialog show={voucherSelect} className="pay-voucher-select">
          <div className="head">
            <div className="title">请选择代金券</div>
            <CloseOutlined className="close" onClick={voucherSelectFalse} />
          </div>
          <div className="body">
            {voucher && voucher == voucherBest ? (
              <div className="tips">
                <div className="text">
                  已选择最优组合，当前组合已抵扣 -¥{voucher?.deductAmount}
                </div>
              </div>
            ) : null}
            <div className="operate">
              <div className="name">使用代金券</div>
              <div className="value">
                <div
                  className={`item ${voucher ? "active" : ""}`}
                  onClick={() =>
                    setVoucher(voucher ? null : getVoucherBest(vouchers))
                  }
                >
                  <CheckOutlined className="icon" />
                </div>
              </div>
            </div>
            <div className="items">
              {vouchers && vouchers.length > 0 ? (
                vouchers.map((item, index) => (
                  <div
                    key={index}
                    className={`item ${item == voucher ? "active" : ""}`}
                    onClick={() => {
                      setVoucher(item);
                      voucherSelectFalse();
                    }}
                  >
                    <div className="amount">
                      <div className="value">
                        <div className="unit">￥</div>
                        <div className="number">{item.amount * 1}</div>
                      </div>
                      <div className="desc">
                        {item.meet_amount > 0
                          ? `满${item.meet_amount * 1}使用`
                          : "任意金额使用"}
                      </div>
                    </div>
                    <div className="info">
                      <div className="name">
                        <div className="type">单游券</div>
                        <div className="named">{item.name}</div>
                      </div>
                      <div className="desc">
                        {dayjs.unix(item.end_time).format("YYYY-MM-DD")} 到期
                      </div>
                    </div>
                    <div className="active">
                      <CheckOutlined className="icon" />
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ padding: "30px 0", textAlign: "center" }}>
                  暂无可用代金券~
                </div>
              )}
            </div>
          </div>
        </PayDialog>
        <PayDialog show={methodSelect} className="pay-method-select">
          <div className="title">请选择充值方式</div>
          <div className="items">
            {methods.map((item) => (
              <div
                key={item.type}
                className={`item ${item.type == method?.type ? "active" : ""} ${
                  !item.enough ? "notenough" : ""
                }`}
                onClick={() => {
                  if (!item.enough) {
                    return;
                  }
                  setMethod(item);
                  methodSelectFalse();
                }}
              >
                <div className="icon">
                  <img
                    src={`/static/img/${item.id || item.type}-icon.png`}
                    alt=""
                  />
                </div>
                <div className="name">
                  <div className="named">{item.name}</div>
                  <div className="tips">
                    {!item.enough ? `余额不足 ${item.amount}` : ""}
                  </div>
                </div>
                <div className="state">
                  <CheckOutlined className="icon" />
                </div>
              </div>
            ))}
          </div>
        </PayDialog>
        {success ? (
          <PaySuccess
            ad={ad}
            order={{ orderId: params.orderId, amount: params.amount }}
          />
        ) : null}
      </div>
    </div>
  );
};
