import { useLockFn, useMount } from "ahooks";
import Dialog from "../general/Dialog";
import axios from "../../axios";
import { useState } from "react";
import Empty from "../general/Empty";

export default () => {
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    setLoading(true);
    await axios
      .get("/order/orderList")
      .finally(() => {
        Dialog.close();
        setLoading(false);
      })
      .then((res) => {
        if (res.data.code != 0) {
          Dialog.error(res.data.msg);
          return;
        }
        setDatas(res.data.data.orderList);
      })
      .catch((err) => {
        Dialog.error(err.message);
      });
  });
  useMount(load);

  return (
    <div className="user-orders">
      {datas.length > 0 ? (
        <div className="items">
          {datas.map((item, index) => (
            <div className="item" key={index}>
              <div className="row">
                <div className="name">{item.pay_type}</div>
                <div className="value">{item.pay_amount}</div>
              </div>
              <div className="row">
                <div className="name">卡券抵扣</div>
                <div className="value">{item.deduction_amount}</div>
              </div>
              <div className="row">
                <div className="name">订单金额</div>
                <div className="value">{item.order_amount}</div>
              </div>
              <div className="row">
                <div className="name">订单号</div>
                <div className="value">{item.order_num}</div>
              </div>
              <div className="row">
                <div className="name">日期</div>
                <div className="value">{item.order_date}</div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {datas.length == 0 && !loading ? <Empty /> : null}
    </div>
  );
};
