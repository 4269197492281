import ActiveContext from "./ActiveContext";
import React, {useEffect, useState} from "react";
import {useScroll, useSetState} from "ahooks";
import $ from "jquery";

export default props => {
  const [cdata, setCdata] = useSetState({});
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    props.active && !mounted && setMounted(true);
  }, [props.active, mounted]);
  const windowScroll = useScroll();
  const [scroll, setScroll] = useState({top: 0, left: 0});
  useEffect(() => {
    if (props.active) {
      $('html,body').scrollTop(scroll.top);
      $('html,body').scrollLeft(scroll.left);
    } else {
      setScroll(windowScroll);
    }
  }, [props.active]);

  return <ActiveContext.Provider value={{...props, ...cdata, setCdata}}>
    {mounted || props.active ? <div className={`active-container ${props.className} ${props.active ? 'active' : ''}`}>
      {props.children}
    </div> : null}
  </ActiveContext.Provider>;
};